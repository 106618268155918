import React from 'react'

import featureRekrytering from '../assets/features/assistansstod-rekrytering.png'
import featureChat from '../assets/features/assistansstod-chat.png'
import featureEkonomi from '../assets/features/assistansstod-ekonomi.png'

import featureRekryteringDark from '../assets/features/assistansstod-rekrytering-dark.png'
import featureChatDark from '../assets/features/assistansstod-chat-dark.png'
import featureEkonomiDark from '../assets/features/assistansstod-ekonomi-dark.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faIdCard,
  faUserPlus,
  faFileInvoiceDollar,
  faComments,
  faPen,
  faCalendarAlt,
  faClock,
  faUsersMedical,
  faBook,
  faShoppingBasket,
  faMoneyBillAlt,
  faSackDollar,
  faFileInvoice,
  faArchive,
  faBars,
  faChartBar,
  faFileUser,
  faCircle,
  faChartLine,
  faArrowsAlt,
  faCommentSmile,
  faRetweet
} from '@fortawesome/pro-solid-svg-icons'

const SectionFeatures: React.FC = (props) => {

  return (
    <section className='sectionFeatures' id='funktionalitet'>

        <div className='sectionIntro'>
            <h3>En Snabb överblick av vår</h3>
            <h2>Funktionalitet</h2>
            <p>Med Assistansstöd har vår ambition alltid varit att täcka in hela processen från nykundsbearbetning till rekrytering av assistenter, kommunikation och uppföljning av uppdrag och verksamheten.</p>
        </div>

        <div className='featureSpotlight'>

            <div className='featureSpotlightItem'>
                <picture>
                    <source srcSet={featureRekryteringDark} media="(prefers-color-scheme: dark)" />
                    <img src={featureRekrytering} alt="Sonika Assistansstöd logotyp" />
                </picture>
                <div className='featureSpotlightContent'>
                    <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faRetweet} size="1x" /></span> Rekrytering</h3>
                    <p>Skapa annonser direkt i Assistansstöd som publiceras till platsbanken så ni kan arbeta effektivare med er rekrytering tillsammans.</p>
                    <p>Kandidaterna hämtas automatiskt tillbaka in i Assistansstöd där ni tillsammans går igenom alla inkomna och hittar den som passar bäst för rollen.</p>
                </div>
            </div>

            <div className='featureSpotlightItem'>
                <picture>
                    <source srcSet={featureChatDark} media="(prefers-color-scheme: dark)" />
                    <img src={featureChat} alt="Sonika Assistansstöd logotyp" />
                </picture>
                <div className='featureSpotlightContent'>
                    <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faCommentSmile} size="1x" /></span> Kommunikation</h3>
                    <p>Förenkla och förbättra den interna kommunikationen med Assistansstöd.</p>
                    <p>Smidig chattfunktionalitet med en-till-en och gruppkonversationer, enkelt att skicka sms vid vakanser eller utskick av e-post vid med information till företaget.</p>
                </div>
            </div>

            <div className='featureSpotlightItem'>
                <picture>
                    <source srcSet={featureEkonomiDark} media="(prefers-color-scheme: dark)" />
                    <img src={featureEkonomi} alt="Sonika Assistansstöd logotyp" />
                </picture>
                <div className='featureSpotlightContent'>
                    <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faChartLine} size="1x" /></span> Ekonomi</h3>
                    <p>Det ska vara enkelt för kundadministratör att ha kontroll över sina uppdrag.</p>
                    <p>Beslutsstöd med diagram och grafik. Systemet hanterar uträkning av lönekostnader och genom integration till bokföringsprogram via API erhålls leverantörsfakturor, korttransaktioner, utlägg och andra kostnadsposter så att organisationen erhåller snabba utfallsrapporter. </p>
                </div>
            </div>

        </div>

        <div className='featureList'>

            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faIdCard} size="1x" /></span> Kund- och personalakt</h3>
                <p>All information om personal och kunder på rätt plats. Enkelt.</p>
            </div>
            {/* <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faIdCard} size="1x" /></span> Kundakt</h3>
                <p>All information om personal och kunder på rätt plats. Enkelt.</p>
            </div> */}

            {/* <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faUserPlus} size="1x" /></span> Rekrytering</h3>
                <p>Publicera annonser till Arbetsförmedlingen och samarbeta kring rekryteringen för respektive kund.</p>
            </div> */}

            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faFileInvoiceDollar} /></span> Digitalt lönebesked</h3>
                <p>Personalen får varje månad sitt lönebesked levererat digitalt i vår app</p>
            </div>

            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faComments} /></span> Krypterad chattfunktion</h3>
                <p>Säker kommunikation en-till-en eller för gruppkonversationer</p>
            </div>
            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faPen} /></span> Avvikelsehantering</h3>
                <p>Skapa ärenden för avvikelser och följ satta processer för uppföljning</p>
            </div>
            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faCalendarAlt} /></span> Schema</h3>
                <p>Kraftfull schemaläggning med allt från grundscheman till semesteransökningar</p>
            </div>

            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faClock} /></span> Tidrapportering</h3>
                <p>Automatiskt skapade tidrapporter från schemat som kompletteras vid behov</p>
            </div>
            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faUsersMedical} /></span> Vikariepool</h3>
                <p>En samling av personal som går att filtrera för att hitta rätt vikarie.</p>
            </div>
            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faBook} /></span> Kvalitetsledningssystem</h3>
                <p>All matnyttig företagsinformation sökbart på ett ställe</p>
            </div>

            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faShoppingBasket} /></span> Beställningar</h3>
                <p>Skapa och hantera beställningar från OneMed</p>
            </div>
            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faMoneyBillAlt} /></span> Utläggs- och kortredovisning</h3>
                <p>Attest direkt i systemet och belastar kostnadsställe.</p>
            </div>
            {/* <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faSackDollar} /></span> Återkommande utbetalningar</h3>
                <p>Utbetalningar läggs </p>
            </div> */}

            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faFileInvoice} /></span> Räkningar och kundfakturor</h3>
                <p>Digital fakturering och se inbetalningar direkt i systemet</p>
            </div>
            {/* <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faArchive} /></span> Dokumentarkivering</h3>
                <p></p>
            </div> */}
            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faBars} /></span> Avräkningsperioder</h3>
                <p>Följ beslut, utförda timmar och inbetalningar direkt i Assistansstöd</p>
            </div>

            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faChartBar} /></span> Undersökningar</h3>
                <p>Samla in information om det som är viktigt inom företaget</p>
            </div>
            <div className='featureItem'>
                <h3><span className='featureItemIcon'><FontAwesomeIcon icon={faFileUser} /></span> Chefssidor</h3>
                <p>En översikt för respektive del av organisationen</p>
            </div>

            </div>

    </section>
  )
}

export default SectionFeatures
