import React from 'react'
import { Button, Flexbox, Text, Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'

import App from './App'
import Header from './Header'
import SectionIntro from './SectionIntro'
import SectionUsps from './SectionUsps'
import SectionFeatures from './SectionFeatures'
import SectionTestimonials from './SectionTestimonials'
import SectionIntegrations from './SectionIntegrations'
import SectionAbout from './SectionAbout'
import SectionFaq from './SectionFaq'
import SectionFooter from './SectionFooter'
import NewsletterForm from './NewsletterForm'

const useStyles = createUseStyles<Theme, 'content' | 'footer' | 'header' | 'link' | 'main'>((theme) => ({
  content: {
    display: 'flex',
    flex: 1,
  },
  footer: {
    background: '#140a47',
    color: '#FFF',
    padding: '2rem',
    ['@media (max-width: 768px)']: {
      padding: '1rem',
    },
    whiteSpace: 'nowrap',
  },
  header: {
    padding: '2rem',
    ['@media (max-width: 768px)']: {
      padding: '1rem',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#fd8f27',
  },
  main: {
    minHeight: '100vh',
  },
}))

const Layout: React.FC = (props) => {
  const { children } = props

  const classes = useStyles()

  return (
    <App>

        <Header />

        <SectionIntro />
        <SectionUsps />
        <SectionFeatures />
        <SectionIntegrations />
        <SectionTestimonials />

        <NewsletterForm />

        <SectionFooter />

    </App>
  )
}

export default Layout
