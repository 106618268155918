import { Button, Card, CardContent, ComponentBaseProps, Flexbox, Form, Input, Theme } from '@sonika-se/ui-kit'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { createUseStyles } from 'react-jss'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import bookMbp from '../assets/bookMbp.png'
import bookPhone from '../assets/bookPhone.png'

const useStyles = createUseStyles<Theme, 'thanks' | 'assistansstodbtn' | 'demoInput'>(() => ({
  thanks: {
    padding: '2rem',
  },
  assistansstodbtn: {
    background: '#fd8f27',
    '&:hover': {
      background: '#ff9900',
    },
    '&:active': {
      background: '#ff9900',
      boxShadow: 'rgba(254, 208, 165, 0.5) 0px 0px 0px 0.25rem'
    },
    '&:focus': {
      background: '#ff9900',
      boxShadow: 'rgba(254, 208, 165, 0.5) 0px 0px 0px 0.25rem'
    }
  },
  demoInput: {
    '&:active': {
      background: '#ffffff',
      borderColor: '#ffb84d !important',
      boxShadow: 'rgba(254, 208, 165, 0.25) 0px 0px 0px 0.25rem !important'
    },
    '&:focus': {
      background: '#ffffff',
      borderColor: '#ffb84d !important',
      boxShadow: 'rgba(254, 208, 165, 0.25) 0px 0px 0px 0.25rem !important'
    }
  }
}))

type NewsletterFormSchema = {
  fname: string,
  lname: string,
  company: string,
  email: string,
  phone: string
}

const validationSchema = yup.object().shape<NewsletterFormSchema>({
  email: yup.string().required(),
  fname: yup.string(),
  lname: yup.string(),
  company: yup.string(),
  phone: yup.string()
})

export type NewsletterFormProps = ComponentBaseProps

export const NewsletterForm: React.FC<NewsletterFormProps> = (props) => {
  const { className, ...rest } = props

  const { errors, handleSubmit, register } = useForm<NewsletterFormSchema>({
    defaultValues: {
      email: '',
      fname: '',
      lname: '',
      company: '',
      phone: ''
    },
    resolver: yupResolver(validationSchema),
  })

  const [state, setState] = useState<'form' | 'post' | 'success' | 'error'>('form')

  const classes = useStyles()

  return (
    <section className='sectionDemo' {...rest} id="BokaDemo">
      <div className='demoBox'>
        <div className='demoBg'>
          <img src={bookMbp} />
        </div>
        <div className='demoContent'>
          <div className='sectionIntro'>
            <h3>Upptäck Assistansstöd</h3>
            <h2>Boka er kostnadsfria demo</h2>
            <p>Fyll i dina kontaktuppgifter så hör vi av oss för att boka upp en demo av Assistansstöd där vi visar vad det kan göra för er verksamhet.</p>
          </div>
          <Flexbox direction="column" gap="small">
          {(state === 'form' || state === 'post') && (
            <Form
              onSubmit={handleSubmit(async (data) => {
                setState('post')

                const { result } = await addToMailchimp(data.email, {
                  FNAME: data.fname,
                  LNAME: data.lname,
                  COMPANY: data.company,
                  PHONE: data.phone
                })

                setState(result)
              })}
              style={{ marginTop: '1rem' }}
            >
              <div className='demoForm'>
                <div className='formRow'>
                  <Input inputName="fname" inputRef={register} placeholder='Förnamn'  className={classes.demoInput} />
                  <Input inputName="lname" inputRef={register} placeholder='Efternamn' className={classes.demoInput} />
                </div>

                <Input inputName="company" inputRef={register} placeholder='Företag' className={classes.demoInput} />

                <div className='formRow'>
                  <Input disabled={state === 'post'} error={errors?.email?.message?.toString()} inputName="email" inputRef={register}  placeholder='E-post' className={classes.demoInput} />
                  <Input inputName="phone" inputRef={register}  placeholder='Telefon' className={classes.demoInput} />
                </div>

                <Button appearance="danger" disabled={state === 'post'} type="submit" className={classes.assistansstodbtn}>Hör av er</Button>

              </div>
            </Form>
          )}
          {(state === 'success' || state === 'error') && (
            <Card background="white" shadow="none">
              <CardContent className={classes.thanks}>
                {state === 'success' && (
                  <p>
                    Tack för ditt intresse! Vi hör av oss inom kort ✌️
                  </p>
                )}
                {state === 'error' && (
                  <p>
                    Något gick fel. Fyllde du i e-postadressen korrekt? {' '}
                    <Button appearance="link" onClick={() => setState('form')} size="small">Försök igen</Button>
                  </p>
                )}
              </CardContent>
            </Card>
          )}
          </Flexbox>
        </div>
        <div className='demoSpacer'>
          <img src={bookPhone} />
        </div>
      </div>
    </section>
  )
}

export default NewsletterForm