import React from 'react'
import { Button, Flexbox, Text, Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'

import sonikaLogo from '../assets/sonika-logo-000.svg'
import sonikaLogoDark from '../assets/sonika-logo-fff.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faFacebook,
  faLinkedinIn,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

const SectionFooter: React.FC = (props) => {
  const { children } = props

  return (
    <div className='sectionFooter'>

        <div className='footerCopyright'>
          <p>© Assistansstöd 2023</p>
        </div>

        {/* <div className='footerSocial'>
          <ul>
            <li><a href="https://www.linkedin.com/company/sonikase" className='button' target='_blank'><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
            <li><a href="#" className='button' target='_blank'><FontAwesomeIcon icon={faFacebook} /></a></li>
            <li><a href="#" className='button' target='_blank'><FontAwesomeIcon icon={faTwitter} /></a></li>
          </ul>
        </div> */}

        <div className='footerBrand'>
          <p>En del av
            <a href="https://www.sonika.se" target='_blank'>
              <picture>
                <source srcSet={sonikaLogoDark} media="(prefers-color-scheme: dark)" />
                <img src={sonikaLogo} alt="Sonika logotyp" />
              </picture>
            </a>
          </p>
        </div>

    </div>
  )
}

export default SectionFooter
