import React from 'react'
import { Button, Flexbox, Text, Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'

import { AnchorLink } from "gatsby-plugin-anchor-links";

import introImage from '../assets/assistansstod-intro.png'
import introImageDark from '../assets/assistansstod-intro-dark.png'

const useStyles = createUseStyles<Theme, 'cta'>((theme) => ({
  cta: {
    background: '#ff9900',
    '&:hover': {
      background: '#000',
    }
  },
}))

const SectionIntro: React.FC = (props) => {

  const classes = useStyles()

  return (
    <section className='sectionHero' id='button-demo'>

        <section className='sectionHeroContent'>
            <h1>Det heltäckande verksamhetssystemet för personlig assistans</h1>
            <p>Assistansstöd från Sonika är ett verksamhetssystem som hjälper assistansföretag att effektivisera administration, kommunikation och uppföljning av verksamheten.</p>
            <AnchorLink to="#BokaDemo" title="Till toppen" className="button">Boka demo</AnchorLink>
        </section>

        <section className='sectionHeroBranding'>
            <picture>
              <source srcSet={introImageDark} media="(prefers-color-scheme: dark)" />
              <img src={introImage} alt="Sonika Assistansstöd logotyp" />
            </picture>
        </section>

    </section>
  )
}

export default SectionIntro
