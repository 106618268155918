import React from 'react'

import integrationBranding from '../assets/integrations/assistansstod-integrationer.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight
} from '@fortawesome/pro-solid-svg-icons'

const SectionUsps: React.FC = (props) => {


  return (
    <section className='sectionIntegrations' id='integrationer'>

        <div className="sectionIntro">
            <h3>Introduktion för integrationer</h3>
            <h2>Integrationer</h2>
            <p>För att effektivisera assistansverksamheten har vi valt att koppla ihop Assistansstöd med flera andra aktörer så vi kan erbjuda säkrare och mer kraftfull funktionalitet tillsammans.</p>
        </div>

        <div className='integrationsContent'>
          <ul>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>Banker</h4>
                <p>Import av korttransaktioner och export av betalfiler</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>Ekonomisystem</h4>
                <p>Inkommande och utgående fakturor och skapa Sie- och PAXML-filer</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>BankID</h4>
                <p>För säker autentisiering & signering</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>Försäkringskassan</h4>
                <p>Skapa och skicka E-räkning, E-tidsredovisning & FK3066</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>Skatteverket</h4>
                <p>Inhämtning av skattetabeller för korrekt skatt i lön</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>Arbetsförmedlingen</h4>
                <p>För publicering av jobbannonser och inhämtning av information om kandidater</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>SPAR</h4>
                <p>För att hämta personuppgifter och adress vid nyanställning</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>Kommunikation</h4>
                <p>Enkelt med epost- och sms-utskick </p>
              </li>
              <li>
                <FontAwesomeIcon icon={faArrowRight} />
                <h4>Förbrukningsartiklar</h4>
                <p>Beställs enkelt genom vår koppling till OneMed</p>
              </li>
          </ul>

          <div className='integrationsBranding'>
              <img src={integrationBranding} />
          </div>
        </div>

    </section>
  )
}

export default SectionUsps
