import React from 'react'
import { Button, Flexbox, Text, Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar
} from '@fortawesome/pro-solid-svg-icons'

import imgTim from '../assets/testimonials/tim.png'
import imgHelene from '../assets/testimonials/helene.png'

const SectionTestimonials: React.FC = (props) => {

  return (
    <section className='sectionTestimonial' id='om-oss'>

        <div className="sectionIntro">
            <h3>Några fina ord om oss</h3>
            <h2>Från användare och kunder</h2>
        </div>

        <div className='testimonialList'>
            <div className='testimonialItem'>
                <p>”Jag har jobbat i de största systemen som finns på marknaden och det tog månader innan jag började förstå systemen. Med Sonika Assistansstöd var jag i princip igång direkt”</p>
                <div className='testimonialAuthor'>
                    <div className='testimonialInfo'>
                        <img src={imgTim} />
                        <div className='testimonialName'>
                            <p><strong>Tim Eriksson</strong></p>
                            <p>Assistans för Dig / Humana</p>
                        </div>
                    </div>
                    <div className='testimonialRating'>
                        <FontAwesomeIcon icon={faStar} size="1x" />
                        <FontAwesomeIcon icon={faStar} size="1x" />
                        <FontAwesomeIcon icon={faStar} size="1x" />
                        <FontAwesomeIcon icon={faStar} size="1x" />
                        <FontAwesomeIcon icon={faStar} size="1x" />
                    </div>
                </div>
            </div>
            <div className='testimonialItem'>
                <p>"Vi har världens bästa system!"</p>
                <div className='testimonialAuthor'>
                    <div className='testimonialInfo'>
                        <img src={imgHelene} />
                        <div className='testimonialName'>
                            <p><strong>Helene Karlsson</strong></p>
                            <p>Alma Assistans (En del av Friab-familjen)</p>
                        </div>
                    </div>
                    <div className='testimonialRating'>
                        <FontAwesomeIcon icon={faStar} size="1x" />
                        <FontAwesomeIcon icon={faStar} size="1x" />
                        <FontAwesomeIcon icon={faStar} size="1x" />
                        <FontAwesomeIcon icon={faStar} size="1x" />
                        <FontAwesomeIcon icon={faStar} size="1x" />
                    </div>
                </div>
            </div>
        </div>

    </section>
  )
}

export default SectionTestimonials