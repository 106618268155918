import React from 'react'
import sonikaLogo from '../assets/assistansstod_logo.svg'
import sonikaLogoDark from '../assets/assistansstod_logo-dark.svg'

const Logo: React.FC = (props) => (
  //<img src={sonikaRgb} style={{ height: 24, width: 226, marginBottom: 0 }} />
  <picture>
    <source srcSet={sonikaLogoDark} media="(prefers-color-scheme: dark)" />
    <img src={sonikaLogo} alt="Sonika Assistansstöd logotyp" />
  </picture>
)

export default Logo
