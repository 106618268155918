import React, { useState, useEffect } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMobileAlt
} from '@fortawesome/pro-solid-svg-icons'

import Logo from './Logo'



export default function Header(props) {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])
  return (

    <div className={scroll ? 'sectionHeader scrolled' : 'sectionHeader'}>

        <AnchorLink to="#" title="Till toppen">
            <Logo />
        </AnchorLink>

        <div className='headerMenu'>
            <ul>
            <li>
                <AnchorLink to="#effektivare-assistans" title="Effektivare assistans">
                    <span>Effektivare assistans</span>
                </AnchorLink>
            </li>
            <li>
                <AnchorLink to="#funktionalitet" title="Vår funktionalitet">
                    <span>Funktionalitet</span>
                </AnchorLink>
            </li>
            <li>
                <AnchorLink to="#integrationer" title="Våra integrationer">
                    <span>Integrationer</span>
                </AnchorLink>
            </li>
            </ul>
        </div>

        <div className='headerPhone'>
            <span className='headerPhoneIcon'>
                <FontAwesomeIcon icon={faMobileAlt} />
            </span>
            <span>
                <p>Ring oss vardagar 09-16</p>
                <h4><a href="tel:010-1994398">010-199 43 98</a></h4>
            </span>
        </div>

    </div>

  )}