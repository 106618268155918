import React from 'react'
import { Button, Flexbox, Text, Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRetweet,
  faLock,
  faCrosshairs
} from '@fortawesome/pro-regular-svg-icons'


const SectionUsps: React.FC = (props) => {

  return (
    <section className='sectionUsps' id='effektivare-assistans'>

        <div className='sectionIntro'>
          <h3>En effektivare assistans</h3>
          <h2>Med högre kontroll och kvalitet</h2>
          <p>Visionen med Assistansstöd är att effektivisera och förenkla assistans för såväl brukare som utförare genom digitaliserade processer och smarta verktyg med hög säkerhet.</p>
        </div>

        <div className='uspList'>

          <div className='uspItem'>
            <h3>
              <span className='uspItemIcon'><FontAwesomeIcon icon={faRetweet} /></span>
              Optimerade processer
            </h3>
            <p>Tillsammans med våra kunder arbetar vi löpande med effektivisering av processer vilket resulterar i en effektivare arbetsmiljö som ger mer tid till assistansen. Det gillar vi.</p>
          </div>

          <div className='uspItem'>
            <h3><span className='uspItemIcon'><FontAwesomeIcon icon={faLock} /></span> Säker utveckling</h3>
            <p>För att upprätthålla en hög säkerhet utvecklar vi Assistansstöd agilt och har koll på GDPR. Som pricken över i:et håller vi alla kunder separerade i egna driftmiljöer.</p>
          </div>

          <div className='uspItem'>
            <h3><span className='uspItemIcon'><FontAwesomeIcon icon={faCrosshairs} /></span> Detaljerad uppföljning</h3>
            <p>Med all information om uppdragen kan ansvariga och chefer enkelt hålla sig ajour med verksamheten såväl hos enskilda kund som på kontors- och företagsnivå.</p>
          </div>

        </div>

    </section>
  )
}

export default SectionUsps
